<template>
    <section class="dingJIn" @click.stop="pickerclick">
        <div class="top-bar bg-white" style="justify-content: space-between;align-items: center">
            <div>
                时<span style="padding: 10px"></span>间 :<span style="padding: 10px"></span>
                <el-select ref="yearRef" v-model="year" placeholder="请选择" size="small">
                    <el-option
                            v-for="(item,index) in 100"
                            :key="index"
                            :label="index+2000"
                            :value="index+2000">
                    </el-option>
                </el-select>
                <span style="padding: 10px">住房类型 :</span>
                <el-select ref="parentRef" v-model="parent" placeholder="请选择" size="small">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="保障性租赁住房" :value="600210"></el-option>
                    <el-option label="长租公寓" :value="600220"></el-option>
                </el-select>
                <span style="padding: 5px"></span>
                <el-select ref="zijiRef" v-model="ziji"  placeholder="请选择" size="small">
                    <el-option v-loading="loading" label="全部" :value="0"></el-option>
                    <el-option
                            v-for="(item,index) in houseTypes"
                            :key="index"
                            :label="item.codeName"
                            :value="item.code">
                    </el-option>
                </el-select>
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
                <!-- 2024/02/13 张晓瑜注释导出 -->
                <!-- <el-button size="small">导出</el-button> -->
                <span style="padding-left: 30px;font-size: 18px;">
<!--                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;">查看已导出列表</el-link>-->
                </span>
                <span style="padding: 15px;font-size: 18px;">
                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;" @click="handleReset">重置筛选条件</el-link>
                </span>
            </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
            <div style="width: 49%;">
                <div>
                    <div class="title">罚没定金支付占比</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;display: flex">
                    <div id="famoDingJinECharts" style="height: 300px;width: 55%"></div>
                    <div style="height: 300px;width: 45%">
                        <div class="operation-item slider" style="width: 100%">
                            <div class="slider-item flex align-center" v-for="item in famoSlider" :key="item.name">
                                <div class="label">{{ item.label }}</div>
                                <el-slider :class="item.name" :value="item.rate" disabled></el-slider>
                                <div class="rate">{{ item.rate }}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 49%;">
                <div>
                    <div class="title">定金转租金支付占比</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;display: flex">
                    <div id="dingJinZhuanRentECharts" style="height: 300px;width: 55%"></div>
                    <div style="height: 300px;width: 45%">
                        <div class="operation-item slider" style="width: 100%">
                            <div class="slider-item flex align-center" v-for="item in zhuanRentSlider" :key="item.name">
                                <div class="label">{{ item.label }}</div>
                                <el-slider :class="item.name" :value="item.rate" disabled></el-slider>
                                <div class="rate">{{ item.rate }}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="dingJInTableRef" :data="[]" :columns="dingJInTableColumn" :query="formSearch" :height="300">
                <template slot="empty">
                    <el-empty></el-empty>
                </template>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import * as echarts from "echarts";
    import {dingJInTableColumn, famoDingJinEChartsOptions, dingJinZhuanRentEChartsOptions} from "@/views/bill-management/rent-withdrawal/data";
    export default {
        name: "dingJIn",
        components: {},
        data() {
            return {
                formSearch: {},
                year: (new Date()).getFullYear(),
                famoDingJinECharts: null,
                dingJinZhuanRentECharts: null,
                famoDingJinEChartsOptions: famoDingJinEChartsOptions,
                dingJinZhuanRentEChartsOptions: dingJinZhuanRentEChartsOptions,
                dingJInTableColumn,
                famoSlider: [
                    {
                        label: "微信",
                        name: "a1",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "支付宝",
                        name: "a2",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "转账",
                        name: "a3",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "现金",
                        name: "a4",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "刷卡",
                        name: "a5",
                        value: 0,
                        rate: 0
                    }
                ],
                zhuanRentSlider: [
                    {
                        label: "微信",
                        name: "a1",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "支付宝",
                        name: "a2",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "转账",
                        name: "a3",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "现金",
                        name: "a4",
                        value: 0,
                        rate: 0
                    },
                    {
                        label: "刷卡",
                        name: "a5",
                        value: 0,
                        rate: 0
                    }
                ],
                parent: 0,
                ziji: 0,
                houseTypes: [],
                loading: false
            };
        },
        methods: {
            eChartsInit(eChartsName, eChartsOptions) {
                this[eChartsName] = echarts.init(document.getElementById(eChartsName));
                this[eChartsName].setOption(eChartsOptions, true);
                window.addEventListener("resize", () => {
                    this[eChartsName].resize();
                });
            },
            handleSearch(){
                this.$refs["dingJInTableRef"].pNumber = 1;
                this.$refs["dingJInTableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {page: 1, pageSize: 10};
                this.parent = 0;
                this.ziji = 0;
                this.year = (new Date()).getFullYear();
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
          // 2024-4-18-新增点击空白关闭组件
          pickerclick(e){
            // console.log( this.itr)
            // this.itr = !this.itr;
            // if(this.itr){
            //   this.$refs['startAndEndDateRef'].hidePicker();
            //   this.$refs['billStartAndEndDateRef'].hidePicker();
            // }
            this.$refs.yearRef.blur()
            this.$refs.parentRef.blur()
            this.$refs.zijiRef.blur()
          },
          pickerclickitr(){
            this.itr = true;
            console.log(this.itr)
          },
        },
        async mounted() {
            this.eChartsInit("famoDingJinECharts", this.famoDingJinEChartsOptions);
            this.eChartsInit("dingJinZhuanRentECharts", this.dingJinZhuanRentEChartsOptions);
        },
        beforeDestroy() {
            window.removeEventListener("resize", () => {
                this.thisYearECharts.resize();
                this.oldDebtECharts.resize();
            });

        },
        watch: {
            async parent(value) {
                if (value !== 0) {
                    this.ziji = 0;
                    this.loading = true;
                    this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
                    this.loading = false;
                } else {
                    this.ziji = 0;
                    this.houseTypes = [];
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dingJIn {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }

        .operation-item {
            height: 100%;
        }
        .slider {
            width: 53%;
            padding: VH(20px) 0 0 VW(20px);
            border-left: 1px dashed #D7D7D7;

            .slider-item {
                padding-bottom: VH(10px);

                .label {
                    width: VW(120px);
                    color: #33558B;
                    font-weight: 600;
                    font-size: rem(18px);
                }

                .value {
                    width: VW(80px);
                    padding: 0 VW(15px);
                    word-break: break-all
                }

                .rate {
                    width: VW(50px);
                }

                /deep/ .el-slider {
                    width: VW(426px);

                    .el-slider__runway, .el-slider__bar {
                        height: VH(13px);
                        border-radius: 9px;
                    }

                    .el-slider__button-wrapper {
                        display: none;
                    }

                    &.a1 .el-slider__bar {
                        background-color: #5087EC;
                    }

                    &.a2 .el-slider__bar {
                        background-color: #68BBC4;
                    }

                    &.a3 .el-slider__bar {
                        background-color: #58A55C;
                    }

                    &.a4 .el-slider__bar {
                        background-color: #F2BD42;
                    }

                    &.a5 .el-slider__bar {
                        background-color: #EE752F;
                    }
                }
            }
        }
    }
</style>
